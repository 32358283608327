import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

import { IconPark, IconPet, IconPool, IconSurf, IconWifi } from "../atoms/Icons"

export const CardServicesSurf = () => {
  return (
    <div className="flex flex-col gap-2 lg:gap-4 items-center text-center max-w-sm my-4">
      <IconSurf />
      <p className="highlight">A pasos de las mejores olas</p>
      <p className="max-w-xs  paragraph -mt-1 lg:-mt-2 leading-8 lg:leading-auto">
        En Nexpa tenemos olas ideales para practicar surf todo el año.
      </p>
    </div>
  )
}
export const CardServicesPool = () => {
  return (
    <div className="flex flex-col gap-2 lg:gap-4 items-center text-center max-w-sm my-4">
      <IconPool />
      <p className="highlight">Contamos con alberca</p>
      <p className="max-w-xs  paragraph -mt-1 lg:-mt-2 leading-8 lg:leading-auto">
        Para pasar momentos inolvidables entre amigos o en familia.
      </p>
    </div>
  )
}
export const CardServicesParking = () => {
  return (
    <div className="flex flex-col gap-2 lg:gap-4 items-center text-center max-w-sm my-4">
      <IconPark />
      <p className="highlight">Estacionamiento propio</p>
      <p className="max-w-xs  paragraph -mt-1 lg:-mt-2 leading-8 lg:leading-auto">
        Contamos con lugar para aparcar tu auto.
      </p>
    </div>
  )
}
export const CardServicesWifi = () => {
  return (
    <div className="flex flex-col gap-2 lg:gap-4 items-center text-center max-w-sm my-4">
      <IconWifi />
      <p className="highlight">Wi Fi</p>
      <p className="max-w-xs  paragraph -mt-1 lg:-mt-2 leading-8 lg:leading-auto">
        Las cabañas cuentan con conexión a internet.
      </p>
    </div>
  )
}
export const CardServicesPet = () => {
  return (
    <div className="flex flex-col gap-2 lg:gap-4 items-center text-center max-w-sm my-4">
      <IconPet />
      <p className="highlight">Aceptamos mascotas</p>
      <p className="max-w-xs  paragraph -mt-1 lg:-mt-2 leading-8 lg:leading-auto">
        Vení a disfrutar unos días de playa con tu mejor amigo.
      </p>
    </div>
  )
}

export const CardCabinGaviota = () => {
  return (
    <div className="max-w-xs rounded-lg shadow-lg ">
      <Link to="/cabin/gaviota">
        <StaticImage
          width={312}
          height={206}
          src="../images/Gaviota/gaviota1.png"
          placeholder="tracedSVG"
          alt="image cabin"
          className="w-full rounded-t-md"
        />
        <div className="flex flex-col gap-2 p-4">
          <p className="card-title">Cabaña Gaviota</p>

          <p className="card-text pb-4">
            Las cabañas cuentan con baño privado, cocina, refrigerador, cama
            matrimonial y una cama individual. Terraza privada con hamacas.
            <br /> Vista al mar.
          </p>
        </div>
      </Link>
      <hr />
      <button className="button-primary mx-auto my-4 px-8 z-20">
        {" "}
        <a className="z-20" href="https://wa.me/527531154050">
          Reservar
        </a>
      </button>
    </div>
  )
}
export const CardCabinPerla = () => {
  return (
    <div className="max-w-xs rounded-lg shadow-lg ">
      <Link to="/cabin/perla">
        <StaticImage
          width={312}
          height={206}
          src="../images/Perla/perla1.png"
          placeholder="tracedSVG"
          alt="image cabin"
          className="w-full rounded-t-md"
        />
        <div className="flex flex-col gap-2 p-4">
          <p className="card-title">Cabaña Perla</p>

          <p className="card-text pb-4">
            Las cabañas cuentan con baño privado, cocina, refrigerador, cama
            matrimonial y una cama individual. Terraza privada con hamacas.
            <br /> Vista al mar.
          </p>
        </div>
      </Link>
      <hr />
      <button className="button-primary mx-auto my-4 px-8 z-20">
        {" "}
        <a className="z-20" href="https://wa.me/527531154050">
          Reservar
        </a>
      </button>
    </div>
  )
}
export const CardCabinAtardecer = () => {
  return (
    <div className="max-w-xs rounded-lg shadow-lg ">
      <Link to="/cabin/atardecer">
        <StaticImage
          width={312}
          height={206}
          src="../images/Atardecer/atardecer1.png"
          placeholder="tracedSVG"
          alt="image cabin"
          className="w-full rounded-t-md"
        />
        <div className="flex flex-col gap-2 p-4">
          <p className="card-title">Cabaña Atardecer</p>

          <p className="card-text pb-4">
            Las cabañas cuentan con baño privado, cocina, refrigerador, cama
            matrimonial y una cama individual. Terraza privada con hamacas.
            <br /> Vista al mar.
          </p>
        </div>
      </Link>
      <hr />
      <button className="button-primary mx-auto my-4 px-8 z-20">
        {" "}
        <a className="z-20" href="https://wa.me/527531154050">
          Reservar
        </a>
      </button>
    </div>
  )
}
export const CardClima = () => {
  return (
    <div className="max-w-xs rounded-lg shadow-lg ">
      <Link to="/cabin/clima">
        <StaticImage
          width={312}
          height={206}
          src="../images/Clima/clima1.png"
          placeholder="tracedSVG"
          alt="image cabin"
          className="w-full rounded-t-md"
        />
        <div className="flex flex-col gap-2 p-4">
          <p className="card-title">Habitaciones Con Clima</p>

          <p className="card-text pb-4">
            Las habitaciones cuentan con baño privado, aire acondicionado, y dos
            camas matrimoniales.
            <br /> Acceso a la alberca.
          </p>
        </div>
      </Link>
      <hr />
      <button className="button-primary mx-auto my-4 px-8 z-20">
        {" "}
        <a className="z-20" href="https://wa.me/527531154050">
          Reservar
        </a>
      </button>
    </div>
  )
}
export const CardHabitaciones = () => {
  return (
    <div className="max-w-xs rounded-lg shadow-lg ">
      <Link to="/cabin/habitaciones">
        <StaticImage
          width={312}
          height={206}
          src="../images/HabitacionesPrivadas/habitaciones1.png"
          placeholder="tracedSVG"
          alt="image cabin"
          className="w-full rounded-t-md"
        />
        <div className="flex flex-col gap-2 p-4">
          <p className="card-title">Habitaciones Privadas</p>

          <p className="card-text pb-4">
            Las habitaciones cuentan con baño privado, ventilador, balcón y dos
            camas matrimoniales.
            <br /> Acceso a la alberca.
          </p>
        </div>
      </Link>
      <hr />
      <button className="button-primary mx-auto my-4 px-8 z-20">
        {" "}
        <a className="z-20" href="https://wa.me/527531154050">
          Reservar
        </a>
      </button>
    </div>
  )
}
export const CardCamp = () => {
  return (
    <div className="max-w-xs rounded-lg shadow-lg ">
      <Link to="/cabin/habitaciones">
        <StaticImage
          width={312}
          height={206}
          src="../images/Acampe.jpg"
          placeholder="tracedSVG"
          alt="image cabin"
          className="w-full rounded-t-md"
        />
        <div className="flex flex-col gap-2 p-4">
          <p className="card-title">Zona de Acampe</p>

          <p className="card-text pb-4">
            Con vista al mar, puedes acampar bajo nuestra palapa y disfrutar de
            los privilegios de dormir junto al sonido del mar.
            <br /> Contamos con baño.
          </p>
        </div>
      </Link>
      <hr />
      <button className="button-primary mx-auto my-4 px-8 z-20">
        {" "}
        <a className="z-20" href="https://wa.me/527531154050">
          Reservar
        </a>
      </button>
    </div>
  )
}
